import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AppContext } from './_context/app_context' ;
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useState } from 'react';

setupIonicReact();

const App = () => {

  const [ myName , setMyName ] = useState( 'SAINT IoT' ) ;
    // ! Gerät derzeit / initial hart auf "2"
  const [ geraetId , setGeraetId ] = useState( 2 ) ;
  const [ geraetName , setGeraetName ] = useState( 'Feedback Button nke Watteco' ) ;
  const [ measures , setMeasures ] = useState( [] ) ;
  const [ measuresFetchanew , setMeasuresFetchanew ] = useState( true ) ;
  const [ measuresFetchstate , setMeasuresFetchstate ] = useState( 0 ) ;

  const initContext = {

    appName: myName ,
    appNameChange: setMyName ,

    appGeraetId: geraetId ,
    appGeraetIdChange: setGeraetId ,
    appGeraetName: geraetName ,
    appGeraetNameChange: setGeraetName ,

    appMeasures: measures ,
    appMeasuresChange: setMeasures ,
    appMeasuresFetchanew: measuresFetchanew ,
    appMeasuresFetchanewChange: setMeasuresFetchanew ,
    appMeasuresFetchstate: measuresFetchstate ,
    appMeasuresFetchstateChange: setMeasuresFetchstate ,

  } ;

  return (
    <IonApp>
      <IonReactRouter>
        <AppContext.Provider value = { initContext } >
          <IonRouterOutlet>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
        </AppContext.Provider>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
