import { useContext , useEffect , useState } from 'react' ;
import { getData } from '../_funkt/get_data' ;
import { AppContext } from '../_context/app_context' ;

export const useMeasures =
  () =>
    {
      const {
        appGeraetId ,
        appMeasuresChange ,
        appMeasuresFetchanew ,
        appMeasuresFetchanewChange ,
        appMeasuresFetchstateChange
      } = useContext( AppContext ) ;

      useEffect(
        () => {
          if( appMeasuresFetchanew ) {
            getData(
              'get_measures.php?geraet_id=' + appGeraetId ,
              appMeasuresFetchstateChange ,
              appMeasuresChange
            ) ;
            appMeasuresFetchanewChange( false ) ;
          }
        } ,
        [ appMeasuresFetchanew, appGeraetId ]
      ) ;

    }
