import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonDatetime, IonGrid, IonInput, IonRow } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../_context/app_context' ;
import { useMeasures } from "../_custom_hooks/use_measures";
  /** => https://react-chartjs-2.js.org/examples/vertical-bar-chart */
// import { ArcElement , CategoryScale , Chart as ChartJS , Legend , LinearScale , LineElement , PointElement , TimeScale , Title , Tooltip } from 'chart.js';
import { ArcElement, BarElement , CategoryScale , Chart as ChartJS, Legend, LinearScale , Tooltip } from 'chart.js';
import { Bar , Doughnut } from 'react-chartjs-2';

const FeedbackBbuttonNKEWwatteco = () => {
  
  const {
    appGeraetId ,
    appGeraetIdChange ,
    appMeasures
  } = useContext( AppContext ) ;

  const [ arrChartjsColors ] = useState( [ 'red' , 'orange' , 'yellow' , 'GreenYellow' , 'green' ] ) ;
  const [ arrChartjsLabels , setArrChartjsLabels ] = useState( [ 'schlecht' , 'eher schlecht' , 'Durchschnitt' , 'eher gut' , 'gut' ] ) ;
  const [ arrChartjsValues , setArrChartjsValues ] = useState( [] ) ;
  const [ dateToShow , setDateToShow ] = useState( new Date().toISOString() ) ;
  const [ displayMeasures , setDisplayMeasures ] = useState( {} ) ;
  const [ intAllVotes , setIntAllVotes ] = useState( 0 ) ;
  const [ localMeasures , setLocalMeasures ] = useState( appMeasures ) ;
  const [ showBy , setShowBy ] = useState( 'totalsCurrent' ) ;
  const [ strVotesAverage , setStrVotesAverage ] = useState( '' ) ;

    // ! Gerät derzeit / initial in App.tsx hart auf "2" gesetzt
  // appGeraetIdChange( 2 ) ;

  /** Chart.js */

    /** Registrierungen für Webpack-Tree-Shaking */
      // ChartJS.register( ArcElement , CategoryScale , Legend , LinearScale , LineElement , PointElement , TimeScale , Title , Tooltip ) ;
      ChartJS.register( ArcElement , BarElement , CategoryScale , LinearScale ,Tooltip , Legend)
  
    /** Daten */
      const objChartjsGesamt = {
        labels: arrChartjsLabels ,
        // labels: [ 'adam' , 'eva' , 'kain' ] ,
        datasets: [
          {
            label: 'Gesamt',
            // data: [ 5, 7 , 8 , 9 , 10 , 0 ],
            data: arrChartjsValues,
            backgroundColor: arrChartjsColors,
            borderColor: [
            ],
            borderWidth: 1,
          }
        ],
      };


  const getSingleDay =
    ( intDateDayMidnight:number ) =>
      {
        if( localMeasures.length > 0 ) {
          const my_objMeasures = {} ;
          for( let i = 0 ; i < 5 ; i++ ) {
            // @ts-ignore
            my_objMeasures[ i ] = {
              'time' : 0 ,
              'data' : 0
            }
          }
          localMeasures
            .forEach(
              ( entry:any ) =>
                {
                  if( entry[ 2 ] > intDateDayMidnight ) {
                    if( entry[ 2 ] < ( intDateDayMidnight + 86400 ) ) {
                      // @ts-ignore
                      if( entry[ 2 ] > my_objMeasures[ entry[ 1 ] ].time ) {
                        // @ts-ignore
                        my_objMeasures[ entry[ 1 ] ].time = entry[ 2 ] ;
                        // @ts-ignore
                        my_objMeasures[ entry[ 1 ] ].data = entry[ 3 ] ;
                      }
                    }
                  }
                }
            )
          return( my_objMeasures ) ;
        }
      }

  const showMeasures =
    ( by:String ) =>
      {
        // @ts-ignore
        switch( by ) {
          case 'totalsByDate' :
            showByDate( Date.parse( dateToShow.split('T')[0] + 'T00:00:00+02:00' ) / 1000 ) ;
            break ;    
          case 'totalsCurrent' :
            showTotals() ;
            break ;    
        }
      }

  const showTotals =
    () =>
      {
        if( localMeasures.length > 0 ) {
          setDateToShow( new Date().toISOString() ) ;
          let objTotals = {} ;
          for( let i = 0 ; i < 5 ; i++ ) {
            // @ts-ignore
            objTotals[ i ] = {
              'time' : 0 ,
              'data' : 0
            }
          }
          localMeasures
            .forEach(
              ( entry:any ) =>
                {
                  // @ts-ignore
                  if( entry[ 2 ] > objTotals[ entry[ 1 ] ].time ) {
                    // @ts-ignore
                    // objTotals[ entry[ 1 ] ][ 'humandate' ] = new Date( entry[ 2 ] * 1000 ) ;
                    // @ts-ignore
                    objTotals[ entry[ 1 ] ].time = entry[ 2 ] ;
                    // @ts-ignore
                    objTotals[ entry[ 1 ] ].data = entry[ 3 ] ;
                  }
                }
            )
        //  console.log( 'objTotals:', objTotals ) ;
          // @ts-ignore
          let my_arrChartjsValues = [] ;
          Object.keys( objTotals )
            .forEach(
              // @ts-ignore
              ( entry:any ) => my_arrChartjsValues.push( objTotals[ entry ].data )
            )
          // @ts-ignore
          // @ts-ignore
          setArrChartjsValues( my_arrChartjsValues ) ;
          setDisplayMeasures( objTotals ) ;
        }
      }
  
  const showByDate =
    ( intDateDayMidnight:number ) =>
      {
        if( intDateDayMidnight !==  Date.parse( new Date().toISOString().split('T')[0] + 'T00:00:00+02:00' ) / 1000 ) {
          setShowBy( 'totalsByDate' ) ; 
        }
        if( localMeasures.length > 0 ) {
          // setDateToShow( new Date( intDateDayMidnight * 1000 ).toISOString() ) ;
          // setDisplayMeasures( [] ) ;
          // alert( 'OK, wir suchen GRÖSSER ' + intDateDayMidnight + ' und KLEINER ' + ( intDateDayMidnight + 86400  ) ) ;
          const objTotals = {} ;
          // console.log( 'objTotals INIT 1:', objTotals ) ;
          for( let i = 0 ; i < 5 ; i++ ) {
            // @ts-ignore
            objTotals[ i ] = {
              'time' : 0 ,
              'data' : 0
            }
          }
          // console.log( 'objTotals INIT:', objTotals ) ;
          localMeasures
            .forEach(
              ( entry:any ) =>
                {
                //  console.log( entry[ 2 ] , 'vs.' , intDateDayMidnight ) ;
                  if( entry[ 2 ] > intDateDayMidnight ) {
                    // console.log( entry[ 2 ] , 'ist größer als' , intDateDayMidnight ) ;
                  //  console.log( entry[ 2 ] , 'vs.' , ( intDateDayMidnight + 86400 ) ) ;
                    if( entry[ 2 ] < ( intDateDayMidnight + 86400 ) ) {
                    //  console.log( entry[ 2 ] , 'ist kleiner als' , ( intDateDayMidnight + 86400 ) ) ;
                      // @ts-ignore
                    //  console.log( entry[ 2 ] , 'vs.' , objTotals[ entry[ 1 ] ].time ) ;
                      // @ts-ignore
                      if( entry[ 2 ] > objTotals[ entry[ 1 ] ].time ) {
                      //  console.log( 'Schreibe' , entry[ 2 ] ) ;
                        // @ts-ignore
                        // objTotals[ entry[ 1 ] ][ 'humandate' ] = new Date( entry[ 2 ] * 1000 ) ;
                        // @ts-ignore
                        objTotals[ entry[ 1 ] ].time = entry[ 2 ] ;
                        // @ts-ignore
                        objTotals[ entry[ 1 ] ].data = entry[ 3 ] ;
                      //  console.log( 'objTotals:', objTotals ) ;
                      }
                    }
                    // console.log( 'objTotals:', objTotals ) ;
                  }
                  // if( ( entry[ 2 ] > intDateDayMidnight ) && ( entry[ 2 ] < ( intDateDayMidnight + 86400 ) ) ) {
                  //   // @ts-ignore
                  //   if( entry[ 2 ] > objTotals[ entry[ 1 ] ].time ) {
                  //     // @ts-ignore
                  //     if( entry[ 2 ] > objTotals[ entry[ 1 ] ].time ) {
                  //       // @ts-ignore
                  //       objTotals[ entry[ 1 ] ].time = entry[ 2 ] ;
                  //       // @ts-ignore
                  //       objTotals[ entry[ 1 ] ].data = entry[ 3 ] ;
                  //     }
                  //   }
                  // }
                }
            )
       // console.log( 'objTotals:', objTotals ) ;
          // @ts-ignore
          let my_arrChartjsValues = [] ;
          let my_intAllVotes = 0 ;
          let my_intVotesPoints = 0 ;
          let my_strVotesAverage = '' ;
          Object.keys( objTotals )
            .forEach(
              ( entry:any ) =>
                {
                  // @ts-ignore
                  my_arrChartjsValues.push( objTotals[ entry ].data ) ;
                  // @ts-ignore
                  my_intAllVotes += Number( objTotals[ entry ].data )
                  // @ts-ignore
                  my_intVotesPoints += ( Number( objTotals[ entry ].data ) * ( Number( entry ) + 1 ) )
                  // @ts-ignore
                  // console.log( '22sep15-1347:' , Number( objTotals[ entry ].data ) ) ;
                  // console.log( '22sep15-1348:' , ( Number( entry ) + 1 ) ) ;
                  // console.log( '22sep15-1346:' , my_intVotesPoints ) ;
                }
            )
          // @ts-ignore
          setArrChartjsValues( my_arrChartjsValues ) ;
          setIntAllVotes( my_intAllVotes ) ;
          // console.log( '22sep15-1345:' , my_intVotesPoints ) ;
          my_strVotesAverage = ( my_intVotesPoints / my_intAllVotes ).toFixed( 2 ).replace( '.' , ',' ) ;
          // console.log( '22sep15-1351:' , my_strVotesAverage ) ;
          setStrVotesAverage( my_strVotesAverage ) ;
          setDisplayMeasures( objTotals ) ;
        }
      }
  
  useMeasures() ;

  useEffect(
    () =>
      {
        // console.log( showBy ) ;
        // console.log( appMeasures ) ;
        setLocalMeasures( appMeasures ) ;
        showMeasures( showBy ) ;
      }
    ,
    [ appMeasures, showBy ]
  ) ;

  useEffect(
    () =>
      {
        showByDate( Date.parse( dateToShow.split('T')[0] + 'T00:00:00+02:00' ) / 1000 );
      }
    ,
    [ dateToShow ]
  ) ;

  return (
    <>
      <div>
        <div
          onClick = { () => setShowBy( 'totalsCurrent' ) }
          style =
            {
              {
                borderBottom: showBy === 'totalsCurrent' ? 'solid thick' : '' ,
                cursor: showBy === 'totalsCurrent' ? 'text' : 'pointer' ,
                float: 'left' ,
                fontWeight: showBy === 'totalsCurrent' ? 'bold' : 'normal' ,
                margin: '0.5em' ,
                opacity: showBy === 'totalsCurrent' ? '1' : '0.5'
              }
            }
        >
          Gesamt heute
        </div>
        <div
          onClick = { () => setShowBy( 'totalsByDate' ) }
          style =
            {
              {
                borderBottom: showBy === 'totalsByDate' ? 'solid thick' : '' ,
                cursor: showBy === 'totalsByDate' ? 'text' : 'pointer' ,
                float: 'left' ,
                fontWeight: showBy === 'totalsByDate' ? 'bold' : 'normal' ,
                margin: '0.5em' ,
                opacity: showBy === 'totalsByDate' ? '1' : '0.5'
              }
            }
        >
          Gesamt nach Datum
        </div>
        <div
          className = 'clearfix'
          style =
            {
              {
                clear: 'both'
              }
            }
        >
          &nbsp;
        </div>
      </div>
      <div
        style =
          {
            {
              marginLeft: '1em'
            }
          }
      >
        {
          // https://www.damirscorner.com/blog/posts/20220107-DatePickerPopupInIonic6.html
          <IonDatetime
            locale = 'de-DE'
            // @ts-ignore
            max = { new Date( Date.parse( new Date() ) + 86400000 ).toISOString() }
            // @ts-ignore
            onIonChange = { ( e ) => setDateToShow( e.target.value ) }
            presentation="date"
            value = { dateToShow }
          />
        }
      </div>
      <IonCard style = { { margin: '0' } }>
        <IonCardHeader>
          <IonCardTitle>
            {
              showBy === 'totalsCurrent'
                ?
                  <span>
                    Gesamt für heute ( { new Date( dateToShow ).toLocaleDateString( 'de-DE' , { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } ) } ) - Gesamtstimmen { intAllVotes } &Oslash; {
                      strVotesAverage } Punkte
                  </span>
                :
                  <span>
                    Gesamt für { new Date( dateToShow ).toLocaleDateString( 'de-DE' , { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } ) } - Gesamtstimmen { intAllVotes } &Oslash; {
                      strVotesAverage } Punkte
                  </span>
            }
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          // style = { { width: '20em' }  }
        >
          <IonGrid>
            <IonRow>
              <IonCol size="2">
                <Doughnut
                  data = { objChartjsGesamt }
                  options = { { } }
                />
              </IonCol>
              <IonCol size="5">
                <Bar
                  data = { objChartjsGesamt }
                  options =
                    {
                      {
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false
                          },
                          title: {
                            display: false
                          },
                        },
                      }
                    }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );

};

export default FeedbackBbuttonNKEWwatteco;
