/**
 *  getData - Datenbezug von Endpoint
 * 
 * @param apiCall       - z.B. "get_measures.php?geraet_id=" 
 * @param setResponse   - Funktion zum Setzen der Fetch-Status-Meldung (typischerweise useState-Funktion)
 * @param setData       - Funktion zum Zwischenspeichern der gefetchten Daten (typischerweise useState-Funktion) 
 * @param sortData      - optional: Funktion zum Sortieren von response.data (in Array mit Objekten)
 *                        - NB: Komponenten wie DataGrid sortieren selbst
 *                        - ! ideal wenn tatsächlich alle Konsumenten die selbe Sortierung haben sollen
 */

  import axios from 'axios';

  export const getData =
    async (
      apiCall:string ,
      setResponse:Function ,
      setData:Function ,
      sortData:any = ''
    ) =>
      {
        setResponse( 100 ) ;
        axios(
          {
            method: 'get',
            url: 'https://iot-backend.saint-online.de/api/' + apiCall ,
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${ window.localStorage.getItem( 'jwt' ) }`,
            },
          }
        )
        .then( ( response ) =>
          { 
            if( response.data.length === 0 ) {
              setData( [] )
              setResponse( 204 ) ;
            } else {
              sortData === ''
                ?
                  setData( response.data )
                :
                  setData( sortData( response.data ) )
              setResponse( response.status ) ;
            }
          }
        )
        .catch( error =>
          { 
            setResponse( error.response.status ) ;
          }
        ) ;
      }
