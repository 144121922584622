import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useContext } from 'react';
import FeedbackBbuttonNKEWwatteco from '../components/feedback-button-nke-watteco';
import './Home.css';

import { AppContext } from '../_context/app_context' ;

const Home: React.FC = () => {
  const { appGeraetName , appName } = useContext( AppContext ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{ appName } { appGeraetName }</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{ appName } { appGeraetName }</IonTitle>
          </IonToolbar>
        </IonHeader>
        <FeedbackBbuttonNKEWwatteco />
      </IonContent>
    </IonPage>
  );
};

export default Home;
